import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { CatalogItemCheckbox } from 'features/CatalogItemCheckbox';
import { CatalogItemImage } from 'shared/ui/CatalogItemImage';
import { useSelector } from 'react-redux';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { useNavigate } from 'react-router-dom';
import { getRouteOrdersViewItemDetailed } from 'shared/const/router';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    getSelectedOrderId,
    getSelectedOrderStatus,
} from 'entities/Order/model/selectors/ordersSelectors';
import { getCanEditCountOfQty } from 'entities/Order/model/selectors/ordersPermissions';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { useGetItemDataUpdate } from 'features/CatalogItemCheckbox/lib/useGetItemDataUpdate';
import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import { UserRole, getUserRole } from 'entities/User';

interface OrderGroupedItemsTableRowsProps {
    item: CatalogItem;
}

export const OrderGroupedItemsTableRow = ({
    item,
}: OrderGroupedItemsTableRowsProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const canSeePrices = useSelector(getUserCanSeePrices);
    const isEditCountOfQty = useSelector(getCanEditCountOfQty);
    const selectedOrderId = useSelector(getSelectedOrderId);
    const selectedOrderStatus = useSelector(getSelectedOrderStatus);
    const userRole = useSelector(getUserRole);

    const shouldSeeWarnings = userRole !== UserRole.MAINTENANCE_TECHNICIAN;

    const isHidePrice = userRole === UserRole.MAINTENANCE_TECHNICIAN;
    const canSeeSeller = !(
        userRole === UserRole.MAINTENANCE_TECHNICIAN &&
        selectedOrderStatus === OrderStatuses.DRAFT
    );

    const canViewProposals = ![UserRole.MAINTENANCE_TECHNICIAN].includes(
        userRole,
    );

    const { isActive, idToEdit, itemIsInOrder, qty } = useGetItemDataUpdate({
        item,
    });

    const proposalsTotal = item?.proposals?.length;
    const sellerName = item?.selectedProposal?.name;
    const sellerActive = item?.selectedProposal?.isOperationsActivated;

    const pricePerItem = item?.selectedProposal?.price || item?.price;

    const total = qty * Number(pricePerItem);

    const openItem = () => {
        navigate(getRouteOrdersViewItemDetailed(selectedOrderId, item.id));
        dispatch(catalogItemActions.setSelectedItem(item));
    };

    return (
        <>
            <TableRow key={item.id}>
                <TableCell>
                    <Box display="flex">
                        <Box
                            width="70px"
                            height="70px"
                            flex="0 0 70px"
                            borderRadius="4px"
                            p="4px"
                            mr="16px"
                        >
                            <CatalogItemImage src={item.thumbnailUrl} />
                        </Box>
                        <Box>
                            <Typography typography={'openSans.subtitle1Medium'}>
                                {item.title}
                            </Typography>
                            <Typography typography={'openSans.body2'}>
                                {'SKU'}: {item.sku}
                            </Typography>
                        </Box>
                    </Box>
                </TableCell>

                {!isEditCountOfQty ? (
                    <>
                        <TableCell
                            sx={{
                                verticalAlign: 'top',
                                boxSizing: 'border-box',
                                width: '44px',
                                maxWidth: '44px',
                            }}
                            align="center"
                        >
                            <Typography typography="openSans.body2">
                                {item.uom}
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                verticalAlign: 'top',
                                boxSizing: 'border-box',
                                width: '44px',
                                maxWidth: '44px',
                            }}
                            align="center"
                        >
                            <Typography typography="openSans.body2">
                                {qty}
                            </Typography>
                        </TableCell>
                        {canSeePrices && !isHidePrice && (
                            <TableCell
                                sx={{
                                    verticalAlign: 'top',
                                    boxSizing: 'content-box',
                                    width: '70px',
                                    maxWidth: '70px',
                                }}
                                align="right"
                            >
                                <Typography
                                    typography="openSans.body"
                                    fontWeight={600}
                                >
                                    ${total}
                                </Typography>
                            </TableCell>
                        )}
                    </>
                ) : (
                    <>
                        <TableCell
                            sx={{
                                verticalAlign: 'top',
                                boxSizing: 'content-box',
                                width: '44px',
                                maxWidth: '44px',
                            }}
                            align="center"
                        >
                            <Typography typography="openSans.body2">
                                {item.uom}
                            </Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                verticalAlign: 'top',
                                boxSizing: 'content-box',
                                width: '132px',
                                maxWidth: '132px',
                            }}
                        >
                            <Box
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <CatalogItemCheckbox
                                    isOrderDetails
                                    hideSwitcher
                                    minimized
                                    showQtyCheckbox
                                    isActive={isActive}
                                    quickEdit
                                    isNoGap
                                    qty={qty}
                                    itemId={item.id}
                                    itemUid={item.uuid}
                                    itemIdToEdit={idToEdit}
                                    isInOrder={itemIsInOrder}
                                />
                                {qty > item.selectedProposal?.quantity &&
                                    shouldSeeWarnings && (
                                        <InfoOutlinedIcon
                                            sx={{
                                                color: 'red',
                                                marginLeft: '8px',
                                            }}
                                        />
                                    )}
                            </Box>
                        </TableCell>
                        {canSeePrices && !isHidePrice && (
                            <TableCell
                                align="right"
                                sx={{
                                    verticalAlign: 'top',
                                    boxSizing: 'content-box',
                                    width: '70px',
                                    maxWidth: '70px',
                                }}
                            >
                                <Typography
                                    typography="openSans.body"
                                    fontWeight={600}
                                >
                                    ${total.toFixed(2)}
                                </Typography>
                            </TableCell>
                        )}
                    </>
                )}
            </TableRow>
            <TableRow>
                <TableCell colSpan={10} sx={{ padding: 0 }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box display="flex" alignItems="center">
                            {canSeeSeller && (
                                <>
                                    <Typography
                                        color={'#1D617A'}
                                        typography="openSans.subtitle1Medium"
                                    >
                                        {canSeeSeller ? sellerName : ''}
                                    </Typography>
                                    {!sellerActive &&
                                        sellerName &&
                                        shouldSeeWarnings && (
                                            <InfoOutlinedIcon
                                                sx={{
                                                    color: 'red',
                                                    marginLeft: '8px',
                                                }}
                                            />
                                        )}
                                </>
                            )}
                        </Box>
                        {proposalsTotal !== 0 && canViewProposals && (
                            <Button sx={{ p: '4px' }} onClick={openItem}>
                                {t('View other suppliers')} (
                                {proposalsTotal || 0})
                            </Button>
                        )}
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={10}></TableCell>
            </TableRow>
        </>
    );
};
