import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Page } from 'widgets/Page';
import { PageTitle } from 'shared/ui/PageTitle/PageTitle';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import {
    getRouteNotFound,
    getRouteOrdersTableByStatus,
    getRouteOrdersViewItemDetailed,
} from 'shared/const/router';
import { OrderViewPageItems } from './OrderViewPageItems';
import { OrderViewPageInfo } from './OrderViewPageInfo';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { fetchPropertyById } from 'features/fetchPropertyById/model/services/fetchPropertyById/fetchPropertyById';
import { fetchOrder } from 'entities/Order';

import {
    getSelectedOrder,
    getSelectedOrderPropertyId,
    getSelectedOrderStatus,
} from 'entities/Order/model/selectors/ordersSelectors';
import {
    getOrderStatusForRoute,
    useGetOrderStatusTitle,
} from 'entities/Order/model/consts/orderStatuses';

import { propertyActions } from 'entities/Property/model/slices/PropertySlice';
import { orderActions } from 'entities/Order/model/slices/OrderSlice';
import {
    getCatalogItems,
    getSelectedCatalogItems,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { fetchCategories } from 'entities/Category';
import { groupBy } from 'shared/lib/lodash/lodash';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { getGroupSummary } from 'pages/OrderViewPage/lib/groupSummary/groupSummary';
import { getPropertyCatalogId } from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';
import {
    getProposalFilterIsActive,
    getProposalFilteredItems,
} from 'features/OrderProposalFilter/model/selectors/orderProposalFilteSelectors';
import RemoveOrderModal from 'shared/ui/RemoveOrderModal/RemoveOrderModal';
import { deleteOrder } from 'widgets/OrdersTable/model/services/deleteOrder/deleteOrder';
import { useFetchProperties } from 'entities/Property/model/lib/useFetchProperties';
import { getUserRole, UserRole } from 'entities/User';
import {
    fetchPropertyVendorsList,
    getIsLoadingVendorsList,
    getPropertyVendorsList,
} from 'entities/Vendors';
import { fetchMetaInfo } from 'entities/Order/model/services/fetchMetaInfo/fetchMetaInfo';

export const OrderViewPage = memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const fetchProperties = useFetchProperties();

    const [orderLoaded, setOrderLoaded] = useState(false);

    const currentUserRole = useSelector(getUserRole);
    const catalogItems = useSelector(getCatalogItems);
    const filteredCatalogItems = useSelector(getProposalFilteredItems);
    const proposalFilterIsActive = useSelector(getProposalFilterIsActive);
    const propertyId = useSelector(getSelectedOrderPropertyId);
    const currentOrder = useSelector(getSelectedOrder);
    const currentOrderStatus = useSelector(getSelectedOrderStatus);
    const propertyCatalogId = useSelector(getPropertyCatalogId);
    const selectedItems = useSelector(getSelectedCatalogItems);

    const vendorsList = useSelector(getPropertyVendorsList);

    const catalogItemsToDisplay = proposalFilterIsActive
        ? filteredCatalogItems
        : catalogItems;

    const orderId = params?.orderId;

    const isDraftTab = currentOrderStatus === 'DRAFT';
    const orderNumber = currentOrder?.orderNumber || '';
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isLoadingDeleteOrder, setIsLoadingDeleteOrder] = useState(false);

    const currentOrderStatusTitle = useGetOrderStatusTitle(currentOrderStatus);

    const isTechnicianUser =
        currentUserRole === UserRole.MAINTENANCE_TECHNICIAN;

    const onBack = useCallback(() => {
        navigate(
            getRouteOrdersTableByStatus(
                propertyId,
                getOrderStatusForRoute(currentOrderStatus),
            ),
        );
    }, [currentOrderStatus, navigate, propertyId]);

    const redirectOnNotFound = useCallback(
        () => navigate(getRouteNotFound()),
        [navigate],
    );

    const initialOrderLoad = useCallback(async () => {
        await fetchProperties(true);
        await dispatch(
            fetchOrder({ id: orderId, onNotFound: redirectOnNotFound }),
        );
        await dispatch(
            fetchMetaInfo({
                id: orderId,
            }),
        );
        setOrderLoaded(true);
    }, [dispatch, orderId, fetchProperties, redirectOnNotFound]);

    useEffect(() => {
        initialOrderLoad();
    }, [initialOrderLoad]);

    const initProperty = useCallback(async () => {
        const response = await dispatch(fetchPropertyById(propertyId));

        if (typeof response.payload !== 'string') {
            const catalogId = response.payload.catalogId;
            await dispatch(fetchCategories(catalogId));
        }

        dispatch(propertyActions.setPropertyId(propertyId));
        dispatch(orderActions.setSearchParamsStatus(currentOrderStatus));
    }, [currentOrderStatus, dispatch, propertyId]);

    const vendorsLoading = useSelector(getIsLoadingVendorsList);

    useEffect(() => {
        if (propertyId) {
            dispatch(fetchPropertyVendorsList(propertyId));
        }
        if (currentOrder?.id) {
            initProperty();
        }
    }, [currentOrder?.id, initProperty, dispatch, propertyId]);

    useEffect(() => {
        if (propertyCatalogId) {
            dispatch(fetchCategories(propertyCatalogId));
        }
    }, [dispatch, propertyCatalogId]);

    const pageTitle = `${t('Order')} ${currentOrder?.orderNumber || ''}`;

    const groupedItems = useMemo(
        () => groupBy(catalogItemsToDisplay, 'categoryId'),
        [catalogItemsToDisplay],
    ) as Record<string, CatalogItem[]>;

    const staticGroupedItems = useMemo(
        () => groupBy(catalogItems, 'categoryId'),
        [catalogItems],
    ) as Record<string, CatalogItem[]>;

    const summaryByCategories = useMemo(
        () => getGroupSummary(staticGroupedItems, selectedItems),
        [staticGroupedItems, selectedItems],
    );

    const isDetailedItem = useMatch(
        getRouteOrdersViewItemDetailed(':orderId', ':itemId'),
    );

    const closeDialog = useCallback(() => {
        setIsShowDialog(false);
    }, []);

    const openDialog = useCallback(() => {
        setIsShowDialog(true);
    }, []);

    const deleteOrderFromList = useCallback(async () => {
        setIsLoadingDeleteOrder(true);
        await dispatch(deleteOrder(orderId));
        toast(t('The order has been deleted'));
        setIsLoadingDeleteOrder(false);
        setIsShowDialog(false);
        onBack();
    }, [dispatch, orderId, onBack, t]);

    const pricingRow = useCallback(
        (label: string, value: string, isBold?: boolean) => (
            <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                    py: '10px',
                }}
            >
                <Typography color="#00000080" typography={'openSans.body2'}>
                    {label}
                </Typography>
                <Typography
                    typography={
                        isBold ? 'openSans.subtitle1Medium' : 'openSans.body2'
                    }
                    style={{ fontWeight: isBold ? 700 : 400 }}
                >
                    {value}
                </Typography>
            </Box>
        ),
        [],
    );

    return (
        <Page bgColor="transparent" padding="0">
            <Box display="flex" gap="16px" height={'calc(100vh - 112px)'}>
                <Box
                    bgcolor="background.paper"
                    borderRadius="16px"
                    p="16px"
                    display="flex"
                    flexDirection="column"
                    flex="2"
                    sx={{ overflowY: 'scroll' }}
                >
                    <Box
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                    >
                        <Box>
                            <PageTitle
                                editTitle={pageTitle}
                                backTitle={t('Orders')}
                                onBack={onBack}
                                isEditing
                                isDraftTab={isDraftTab}
                                isShowDialog={isShowDialog}
                                openDialog={openDialog}
                                isLoading={!orderLoaded}
                            />
                        </Box>
                        {orderLoaded && (
                            <Typography
                                alignSelf="flex-end"
                                typography="openSans.subtitle1Medium"
                                mb="28px"
                            >
                                {currentOrderStatusTitle}
                            </Typography>
                        )}
                    </Box>

                    {currentOrder && (
                        <OrderViewPageInfo
                            summaryByCategories={summaryByCategories}
                            isLoading={!orderLoaded}
                        />
                    )}
                    {currentOrder && orderLoaded && !isTechnicianUser && (
                        <Box sx={{ marginTop: 'auto' }}>
                            {pricingRow(
                                t('Subtotal'),
                                `$${currentOrder.price.toFixed(2)}`,
                            )}
                            <Box
                                sx={{ height: '1px', background: '#E9E8E8' }}
                            />
                            {pricingRow(
                                t('Shipping'),
                                t('Determined by supplier'),
                            )}
                            <Box
                                sx={{ height: '1px', background: '#E9E8E8' }}
                            />
                            {pricingRow(
                                t(
                                    currentOrder.status.toLowerCase() ===
                                        'invoiced'
                                        ? 'Tax'
                                        : 'Estimated tax',
                                ),
                                `$${currentOrder.tax.toFixed(2)}`,
                            )}
                            <Box
                                sx={{ height: '1px', background: '#000000' }}
                            />
                            {pricingRow(
                                t(
                                    currentOrder.status.toLowerCase() ===
                                        'invoiced'
                                        ? 'Total'
                                        : 'Estimated total',
                                ),
                                `$${currentOrder.totalPrice.toFixed(2)}`,
                                true,
                            )}
                        </Box>
                    )}
                </Box>
                <Box bgcolor="background.paper" borderRadius="16px" flex="3">
                    {!isDetailedItem && orderLoaded && !vendorsLoading ? (
                        <OrderViewPageItems
                            vendors={vendorsList}
                            groupedItems={groupedItems}
                            onBack={onBack}
                        />
                    ) : (
                        orderLoaded && <Outlet />
                    )}
                </Box>
            </Box>
            <RemoveOrderModal
                isShowDeleteOrder={isShowDialog}
                title={t('Delete the Order')}
                text={`${t(
                    'You are about to delete the Draft Order ID',
                )}: ${orderNumber}`}
                isLoadingDeleteOrder={isLoadingDeleteOrder}
                onCloseDeleteOrder={closeDialog}
                onSubmitDeleteOrder={deleteOrderFromList}
            />
        </Page>
    );
});
