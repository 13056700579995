import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
    alpha,
} from '@mui/material';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { getLocalCategoriesList } from 'entities/Category';
import { useSelector } from 'react-redux';
import { OrderGroupedItemsTable } from './OrderGroupedItemsTable';
import { ArrowDropDown } from '@mui/icons-material';
import { getSelectedOrder } from 'entities/Order/model/selectors/ordersSelectors';

interface OrderGroupedItemsTableProps {
    categoryId: string;
    items: CatalogItem[];
}

export const OrderGroupedItems = ({
    categoryId,
    items,
}: OrderGroupedItemsTableProps) => {
    const categories = useSelector(getLocalCategoriesList);
    const currentOrder = useSelector(getSelectedOrder);

    const title = categories?.[categoryId]?.title;
    const glCode = categories?.[categoryId]?.glCode;
    const color = categories?.[categoryId]?.color;
    const itemsCount = items.length;

    return (
        <Box sx={{ padding: '10px' }}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    sx={(theme) => ({
                        bgcolor: alpha(theme.palette.grey[500], 0.5),
                        p: '14px 16px',
                        borderRadius: '4px 4px 0 0 ',
                    })}
                    expandIcon={
                        <ArrowDropDown
                            sx={(theme) => ({
                                color: alpha(theme.palette.common?.black, 0.3),
                            })}
                        />
                    }
                >
                    <Box display="flex" alignItems="center" gap="12px">
                        <Box
                            width="8px"
                            height="8px"
                            borderRadius="50%"
                            overflow="hidden"
                            sx={{ bgcolor: color }}
                        ></Box>
                        <Box display={'flex'}>
                            <Typography typography={'openSans.body2'}>
                                {title} {`(${itemsCount})`}
                            </Typography>
                            <Typography
                                sx={(theme) => ({
                                    color: alpha(
                                        theme.palette.common?.black,
                                        0.5,
                                    ),
                                })}
                                typography={'openSans.body2'}
                            >
                                {glCode ? ` | ${glCode}` : ''}
                            </Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box p="12px 16px 0">
                        <OrderGroupedItemsTable
                            items={items}
                            orderStatus={currentOrder?.status}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
